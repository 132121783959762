import ErrorPage from '../components/pages/ErrorPage/ErrorPage';
import { buildPage, buildStaticProps } from '../utils/pageHelper';

export const getStaticProps = buildStaticProps('index');
export default buildPage(ErrorPage.bind(null, { statusCode: 404 }), {
  fullPage: true,
  metaData: {
    title: 'Not found',
    description: 'Page not found',
  },
});
